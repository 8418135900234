import React from 'react'
import { Link } from 'gatsby'
import Moment from 'moment'

import styles from './MessageForm.module.scss'
import Button from 'components/shared/Button'
import {
  FormInput,
  Alert,
  FormContainer,
  FormRow,
  FormCol,
} from 'components/shared/Forms'
import UserBlurb from 'components/Users/UserBlurb'
import Card from 'components/shared/Card'
import { toDate } from 'utils/conversions'

Moment.locale('en')

const ReplyMessage = ({ reply }) => {
  if (!reply) {
    return <div />
  }
  return (
    <div className="reply-body">
      <p className="hint-text">{reply.body}</p>
      <p className="sent-time"> {toDate(reply.createdAt, 'LLLL')}</p>
    </div>
  )
}

const SentMessage = ({ message }) => {
  if (!message) {
    return <div />
  }
  return (
    <div className={styles.messageBody}>
      <p className="hint-text">{message}</p>
      <p className="sent-time">{Moment().fromNow()}</p>
    </div>
  )
}

export default class MessageForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      sentMessage: '',
      sending: false,
      success: false,
      error: null,
    }
  }

  _submit = async e => {
    e.preventDefault()
    this.setState({
      success: false,
      sending: true,
      sentMessage: this.state.message,
      error: null,
    })
    try {
      const error = await this.props.onSubmit({ message: this.state.message })
      if (error) {
        console.log(error)
        return this.setState({ error, sending: false })
      }
      this.setState({ success: true, sending: false, message: '' })
    } catch (error) {
      console.log(error)
      this.setState({ error, sending: false, success: false })
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { reply = null, recipient = {} } = this.props
    let { sending, success, message, sentMessage, error } = this.state

    const isInvalid = message == ''
    return (
      <FormContainer width="450px">
        <ReplyMessage reply={reply} />
        {success && <SentMessage message={sentMessage} />}
        <Card title={`Send a message`}>
          <div className={styles.avatarContainer}>
            <UserBlurb user={recipient} showName />
          </div>
          <form onSubmit={this._submit}>
            <FormRow>
              <FormCol>
                <FormInput label="Message">
                  <textarea
                    name="message"
                    required
                    maxLength="450"
                    rows="3"
                    value={message}
                    onChange={this.onChange}
                  />
                </FormInput>
              </FormCol>
            </FormRow>
            <Button big full disabled={isInvalid}>
              {sending ? 'Sending...' : 'Send'}
            </Button>
          </form>

          <div className={styles.alertContainer}>
            <Alert
              type="success"
              visible={success}
              message="Message sent successfully"
            />
            <Alert
              type="success"
              visible={error}
              message="Error sending message"
            />
          </div>
        </Card>
      </FormContainer>
    )
  }
}
