import React from 'react'
import qs from 'qs'
import { navigate } from 'gatsby'

import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import MessageForm from 'components/MessagePage/MessageForm'

const urlToSearchState = url => qs.parse(url)

export class MessagePage extends React.Component {
  state = {
    recipient: {},
    reply: null,
    isReply: false,
  }

  componentDidMount() {
    const storedURL = this.props.location.search.slice(1)
    if (storedURL) {
      const recipientID = urlToSearchState(storedURL).recipient
      const replyID = urlToSearchState(storedURL).reply
      let data = { recipientID, replyID }
      this.loadData(data)
    } else {
      navigate('/')
    }
  }

  loadData = async data => {
    let { recipientID, replyID } = data
    try {
      let recipient,
        reply = {}
      if (recipientID) {
        let recipientSnap = await this.props.database.getUserById(recipientID)
        if (!recipientSnap.exists) {
          navigate('/')
        }
        recipient = recipientSnap.data()
        recipient.id = recipientSnap.id
        this.setState({ recipient, replyToID: recipient.id })
      } else if (replyID) {
        let messageSnap = await this.props.database.getMessageById(replyID)
        reply = messageSnap.data()
        reply.id = messageSnap.id

        this.checkIfRecipient(reply.to.id)

        let recipientSnap = await this.props.database.getUserById(reply.from.id)
        recipient = recipientSnap.data()
        recipient.id = recipientSnap.id

        if (!messageSnap.exists || !recipientSnap.exists) {
          navigate('/')
        }
        this.setState({ recipient, reply })
      }
    } catch (error) {
      console.log(error)
    }
  }

  checkIfRecipient = recipient_id => {
    if (this.props.currentUser.uid != recipient_id) {
      navigate('/')
    }
  }

  onSubmit = async data => {
    try {
      let fb = await this.props.database.createMessage({
        ...data,
        recipientID: this.state.recipient.id,
      })
    } catch (error) {
      return error
    }
  }

  render() {
    let { recipient, reply } = this.state
    return (
      <MessageForm
        recipient={recipient}
        reply={reply}
        onSubmit={this.onSubmit}
      />
    )
  }
}

export default ({ location }) => (
  <Layout displayFooter={false} location={location}>
    <SEO title="Send a message" />
    <MessagePage />
  </Layout>
)
